/**
 * 企業詳細フォーム
 *
 * @export
 * @interface ICompanyCreateAllRequest
 */

export interface ICompanyCreateAllRequest {
    company_create_count: number | null;
    password: string | null;
    company_control_number: string;
    company_corporate_number: string;
    company_has_no_bank_branch: number;
    company_bb_id: number;
    company_control_number_2: string;
    company_bank_account_type: string;
    company_bank_account_number: number;
    company_name: string;
    company_name_kana: string;
    company_tel: string;
    company_rep_name: string;
    company_account_closing_month: number;
    company_employee_count: number;
    company_website: string;
    company_cbt_1_code: string;
    company_cbt_2_code: string;
    company_small_business_type: string;
    company_subscribe_reason: string;
    company_type: string;
    company_status: string;
    company_accept_mm: number;
    company_is_other_manager: number;
    company_other_managers: string;
    company_check_anti_social: number;
    company_is_matching_fee: number;
    company_private_memo: string;
    company_address: {
      main: {
        post_code_1: string;
        post_code_2: string;
        pref_name: string;
        address_1: string;
        address_2: string;
      };
    },
    company_service_offer_api_url: string;
    owner: {
      user_last_name: string;
      user_first_name: string;
      user_last_name_kana: string;
      user_first_name_kana: string;
      user_email: string;
      user_tel: string;
    }
    partner_request: number;
    company_label_ids: any;
    company_is_demo: number | null;
}
