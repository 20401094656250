import { USER_TYPE } from '@/configs';
import { OPERATION_PERMISSIONS } from '@/configs/enums/permissions';

/**
 * 必要なアクセス条件
 * - スーパーユーザーの場合は権限チェックをスキップされます
 */
export interface IAccessCondition {
  accessCondition: {
    /** 必要な権限 */
    permissions: OPERATION_PERMISSIONS[];
    /**
     * 許可されているユーザータイプの一覧
     * - 空の場合、全てのユーザータイプに対してアクセスが許可される。
     */
    allowedUserTypes: (typeof USER_TYPE)[keyof typeof USER_TYPE][];
  };
}

/** カスタムアクセス */
export interface ICustomAccessCondition {
  customAccessCondition: {
    /** アクセス可能かどうかを判定する関数 */
    canAccess: () => boolean;
  };
}

/** アクセス権限の定義 */
export interface IAccessPermission {
  /** ルートパス */
  routePath: RegExp;

  /** アクセス権限のタイプ */
  item: IAccessCondition | ICustomAccessCondition;

  /** エラー */
  errorInfo: {
    statusCode: number;
    errorMessage: string;
  };
}
